var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { attrs: { fluid: "" } }, [
    _c(
      "div",
      { staticClass: "filter-bar p-2 mb-3" },
      [
        _c(
          "b-navbar",
          { attrs: { toggleable: "xl" } },
          [
            _c("b-navbar-toggle", { attrs: { target: "filter-collapse" } }),
            _c(
              "b-collapse",
              { attrs: { id: "filter-collapse", "is-nav": "" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "filter-btn reset",
                    on: {
                      click: function($event) {
                        return _vm.resetFilters()
                      }
                    }
                  },
                  [_vm._v("Reset Filters")]
                ),
                _c("b-form-select", {
                  staticClass: "select company",
                  attrs: {
                    options: _vm.companies,
                    "text-field": "name",
                    "value-field": "id",
                    plain: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null } },
                            [_vm._v("Select Company")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.filters.company,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "company", $$v)
                    },
                    expression: "filters.company"
                  }
                }),
                _c("b-form-select", {
                  staticClass: "select company",
                  attrs: {
                    options: _vm.teams,
                    "text-field": "teamname",
                    "value-field": "teamname",
                    plain: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null } },
                            [_vm._v("Select Team")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.filters.team,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "team", $$v)
                    },
                    expression: "filters.team"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "d-inline-block ml-4" },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "d-inline",
                      attrs: { value: "1", "unchecked-value": "0" },
                      model: {
                        value: _vm.filters.open,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "open", $$v)
                        },
                        expression: "filters.open"
                      }
                    }),
                    _vm._v(" Show all open ")
                  ],
                  1
                ),
                _c("div", { staticClass: "d-inline-block ml-4 full-width" }, [
                  _vm._v("Due:")
                ]),
                _c("b-form-select", {
                  staticClass: "select",
                  attrs: { options: _vm.years, plain: "" },
                  model: {
                    value: _vm.filters.year,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "year", $$v)
                    },
                    expression: "filters.year"
                  }
                }),
                _c("b-form-select", {
                  staticClass: "select",
                  attrs: { options: _vm.periods, plain: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null } },
                            [_vm._v("Month")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.period,
                    callback: function($$v) {
                      _vm.period = $$v
                    },
                    expression: "period"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "filter-btn reset button-right",
                    on: {
                      click: function($event) {
                        return _vm.exportData()
                      }
                    }
                  },
                  [
                    _vm._v("Export "),
                    _c("b-icon", { attrs: { icon: "download" } })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.stats
      ? _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.loading } },
              [
                _c(
                  "b-card",
                  { staticClass: "card-border table-card text--black" },
                  [
                    _c("b-table", {
                      staticStyle: { "min-width": "1500px" },
                      attrs: {
                        items: _vm.stats,
                        fields: _vm.fields,
                        "foot-clone": "",
                        "no-footer-sorting": "",
                        "details-td-class": "no-details-padding",
                        fixed: "",
                        hover: ""
                      },
                      on: { "row-clicked": _vm.expand },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "head(open)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Open " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.open + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(hold)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Hold " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.hold + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(late)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Late " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.late + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(waiting)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Waiting " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.waiting + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(review)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Review " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.review + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(complete)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Complete " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.complete + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(cwq)",
                            fn: function() {
                              return [
                                _vm._v(" Closed w/"),
                                _c("br"),
                                _vm._v(
                                  "Questions " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.cwq + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(quaterly)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Quarterly " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.quaterly + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(semi)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Semi-Annual " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.semi + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(annual)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Annual " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.annual + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "head(total)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " Total " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? "(" + _vm.totals.total + ")"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(name)",
                            fn: function(row) {
                              return [
                                row.item.name !== "Onboarding Tickets"
                                  ? _c("b-icon", {
                                      staticClass: "float-left mt-1",
                                      attrs: {
                                        icon: row.detailsShowing
                                          ? "caret-down-fill"
                                          : "caret-up-fill"
                                      }
                                    })
                                  : _vm._e(),
                                row.item.name != "Onboarding Tickets"
                                  ? _c("span", [
                                      _vm._v(" " + _vm._s(row.item.name) + " ")
                                    ])
                                  : _vm._e(),
                                row.item.name === "Onboarding Tickets"
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-center font-weight-bold",
                                          attrs: { colspan: _vm.fields.length }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.item.name) + " "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "row-details",
                            fn: function(row) {
                              return [
                                row.item.name !== "Onboarding Tickets"
                                  ? _c("b-table", {
                                      attrs: {
                                        fixed: "",
                                        items: row.item.details,
                                        fields: _vm.detailfields
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(fullname)",
                                            fn: function(row) {
                                              return [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "text--black u",
                                                    attrs: {
                                                      to:
                                                        "/bookkeeping?assignee=" +
                                                        row.item.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(row.item.fullname)
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "cell(total)",
                            fn: function(row) {
                              return [
                                row.item.name !== "Onboarding Tickets"
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm._f("total")(row.item)))
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "foot(name)",
                            fn: function() {
                              return [_vm._v(" Totals ")]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(open)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null ? _vm.totals.open : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(hold)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null ? _vm.totals.hold : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(late)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null ? _vm.totals.late : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(waiting)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.waiting
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(review)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.review
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(complete)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.complete
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(cwq)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null ? _vm.totals.cwq : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(quaterly)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.quaterly
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(semi)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null ? _vm.totals.semi : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(annual)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.annual
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "foot(total)",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.totals !== null
                                        ? _vm.totals.total
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2975991017
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }